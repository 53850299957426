// @flow

import React from 'react';

import JsonSchemaFormSuite from './JsonSchemaFormSuite';


// Can be used to set initial schemas and mods (useful for development)
// const initialJsonSchema = {};
// const initialUiSchema = {};
const mods = {};

export default function PlaygroundContainer({ui, initialJsonSchema, initialUiSchema}) {

    const [schema, setSchema] = React.useState(JSON.stringify(initialJsonSchema));
    const [uischema, setUischema] = React.useState(
        JSON.stringify(initialUiSchema),
    );

    console.log('schema', schema);
    console.log('uischema', uischema);


    return (
        <div className='playground'>
            <JsonSchemaFormSuite
                lang={'json'}
                schema={schema}
                uischema={uischema}
                mods={mods}
                schemaTitle='Data Schema'
                uischemaTitle='UI Schema'
                onChange={(newSchema, newUiSchema) => {
                    setSchema(newSchema);
                    setUischema(newUiSchema);
                    const store = JSON.stringify({
                        jsonSchema: JSON.parse(newSchema),
                        uiSchema: JSON.parse(newUiSchema)
                    });
                    ui?.document?.field?.setValue(store);
                }}
                width='95%'
                height='800px'
            />
        </div>
    );
}
