import React, {useEffect, useState} from 'react';
import PlaygroundContainer from './PlaygroundContainer';

async function getSchemaObjectFromString(ui) {
    let ret
    try {
        const value = await ui.document.field.getValue();
        ret = JSON.parse(value);
    } catch (e) {
        ret = {jsonSchema: {}, uiSchema: {}}
    }
    return ret
}

function App({ui}) {

    const [initialJsonSchema, setInitialJsonSchema] = useState({});
    const [initialUiSchema, setInitialUiSchema] = useState({});
    const [show, setShow] = useState(false);

    const getSchemas = async () => {
        const combined = await getSchemaObjectFromString(ui)
        console.log('combined', combined)
        setInitialJsonSchema(combined.jsonSchema);
        setInitialUiSchema(combined.uiSchema);
        setShow(true);
    };


    useEffect(() => {
        getSchemas();
    }, []);


    return <>
        {show && <PlaygroundContainer initialJsonSchema={initialJsonSchema} initialUiSchema={initialUiSchema} ui={ui}/>}
    </>;
}

export default App;
