import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import UiExtension from "@bloomreach/ui-extension";


document.addEventListener('DOMContentLoaded', async () => {
    try {
        const ui = await UiExtension.register();
        ReactDOM.render(<App ui={ui}/>, document.getElementById('root'));
    } catch (error) {
        console.log(error);
        console.error('Failed to register extension:', error.message);
        console.error('- error code:', error.code);
        ReactDOM.render(<App ui={undefined}/>, document.getElementById('root'));
    }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
